import React, { useContext, useState, useRef } from 'react'
import useDynamicRefs from 'use-dynamic-refs'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotlightArticle from "../components/SpotlightArticle"
import otherEssSpotlight from "../media/img/development/cheet-sheet-spotlight.jpg"

import {Container, Card, AccordionContext, useAccordionToggle,Accordion } from "react-bootstrap"

import Wiedenism from '../components/Wiedenism'







export default function CheatSheet(props) {
    const [activeTab, setActiveTab] = useState(0);
    const [getRef, setRef] =  useDynamicRefs();
    const tabRef = useRef(null)
    const accrRef = useRef(null)
    const headerHeight = typeof window !== 'undefined' ? document.querySelector('header').offsetHeight + 10: null;
    const executeScroll = () => {
        window.scrollTo(0, tabRef.current.offsetTop - headerHeight, 'smooth');
    }
    
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => {
                callback && callback(eventKey)
                setTimeout(() => {
                    window.scrollTo(0, getRef(eventKey).current.offsetTop - headerHeight, 'smooth')
                }, 600); 
                
            },
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <Accordion.Toggle
            className={isCurrentEventKey ? `head active` : `head` }
            onClick={decoratedOnClick}
            id={eventKey}
            ref={setRef(eventKey)}
            >
            {children}
            </Accordion.Toggle>
        )
        }

    return (
        <Layout pageInfo={{ pageName: "The Cheat Sheet" }}>
            <SEO title="The Cheat Sheet" />
            <SpotlightArticle tags={`The Cheat Sheet`} title={`This here is your ready reckoner, a guide to all things W+K. It outlines everything from your induction and onboarding program to HR and Finance & IT practices – so it’s all smooth sailing from day one. We want to make sure you feel completely comfortable here so the only thing you have to worry about is making the best work of your life. So, let’s get started!`} bgImg={otherEssSpotlight} borderColor="#af14ba" />
            <section className="policy">
                <Container>
                    <div className="policy__Header">
                        <div className="categories" ref={tabRef}>
                            <ul>
                                {props.data.allIntranet.edges.map((heading, tabIndex) => {
                                    return (
                                        <li key={heading.node.id} className={activeTab === tabIndex?`active`:``} id={tabIndex} onClick={() => { setActiveTab(tabIndex); executeScroll()}} onKeyDown={() => setActiveTab(tabIndex)} role="presentation">{heading.node.title}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <hr />
                    {props.data.allIntranet.edges.map((heading, parentIndex) => (
                        heading.node.description.map((subHead, i) => (
                            <>
                            <div className="to--scroll--target"></div>
                            <div className={activeTab===parentIndex ? `policy__Content active`:`policy__Content`} key={subHead.sub_id} data-id={parentIndex}>
                                <div className="heading" key={i}>
                                    {subHead.sub_Category}
                                </div>
                                <div className="accordion">
                                    <Accordion>
                                        {
                                            subHead.details.map((blocks,i) => (
                                                <React.Fragment key={i}>
                                                    <ContextAwareToggle eventKey={i+1}>
                                                        {blocks.heading}
                                                    </ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={i+1} >
                                                        <Card.Body className="content" dangerouslySetInnerHTML={{__html:blocks.content}} />
                                                    </Accordion.Collapse>
                                                </React.Fragment>
                                            ))
                                        }
                                    </Accordion>
                                </div>
                                <hr />
                            </div>
                            </>
                            )
                        ))
                    )}
                </Container>
                <Wiedenism />
                {/* <div className="home__relative__links">
                        <Container>
                            <Row className="d-flex">
                                <Col>
                                    <BorderLayout color={`#A8233D`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`Who We Are`} slug={`/who-we-are`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                                <Col>
                                    <BorderLayout color={`#FFB100`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`Learn & Grow`} slug={`/learn-and-grow`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                                <Col>
                                    <BorderLayout color={`#8163E7`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`Other Essentials`} slug={`/other-essentials`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                                <Col>
                                    <BorderLayout color={`#A8233D`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`What's Happening`} slug={`/whats-happening`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
            </section>
        </Layout>
    )
}
export const query = graphql`
    query Policy {
        allIntranet {
            edges {
                node {
                    id
                    title
                    description {
                        details {
                            heading
                            content
                        }
                        sub_Category
                        sub_id
                        link
                    }
                    link
                }
            }
        }
    }
`